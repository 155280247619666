const SETTINGS = {
    chains: [
        '159M5wyRvMuBMqJrieLGT2ts6hcuhtp2PK'
    ],
    peers: [
        'ws://47.74.47.117:32767/',
        'ws://47.245.31.221:32767/'
    ]
}

export default SETTINGS
